import React from "react";

import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Popper from "@material-ui/core/Popper";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import { Avatar, Tooltip } from "@material-ui/core";
import amber from "@material-ui/core/colors/amber";
import { Link } from "react-router-dom";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import MuiAlert from "@material-ui/lab/Alert";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  NativeSelect,
  Snackbar,
  LinearProgress,
  TextField,
  Button,
} from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import InsertChartIcon from '@material-ui/icons/InsertChart';
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import SupervisorAccount from "@material-ui/icons/SupervisorAccount";
import EventIcon from "@material-ui/icons/Event";
import AssessmentIcon from "@material-ui/icons/Assessment";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import VisibilityIcon from "@material-ui/icons/Visibility";
import BarChartIcon from "@material-ui/icons/BarChart";
import ScheduleIcon from "@material-ui/icons/Schedule";
import ForumIcon from "@material-ui/icons/Forum";
import GroupIcon from "@material-ui/icons/Group";
import SchoolIcon from "@material-ui/icons/School";
import TMSGuide from "../assets/TMS_user_manual_24092021.pdf";
import Tms_image from "./tms-logo.svg";

import './Navbar.css';


function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,1)",
    margin: "0px 10px 0px",
    fontSize: "19px",
    marginTop: "0",
    marginBottom: "0",
  },

  small: {
    height: theme.spacing(4),
    width: theme.spacing(4),
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    backgroundColor: "#2474FC",
    marginRight: 36,
    color: "#fff",
    "&:hover": {
      background: "#4DBDC1",
    },
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    color: "#4DBDC1",
    flexGrow: 1,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    backgroundColor: "white",
    width: drawerWidth,
    boxShadow:
      "0 16px 38px -12px rgba(0, 0, 0, 0.56), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  Media: {
    height: 58,
    width: "70%",
  },
  Main: {
    height: 58,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  paperPop: {
    marginRight: theme.spacing(2),
  },
  fixedHeight: {
    height: 260,
  },
}));

const drawerWidth = 270;

export default function Navbar() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [popopen, setPopopen] = React.useState(false);
  const [user, setUser] = React.useState([]);
  // get HR admin info
  const [hrAdmin, sethrAdmin] = React.useState(null);
  const popanchorRef = React.useRef(null);

  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [types, setTypes] = React.useState([]);
  const [feedbackAlert, setFeedbackAlert] = React.useState(false);
  const [fillFeedback, setFillFeedbackAlert] = React.useState(false);
  const [feedbackError, setFeedbackError] = React.useState(false)
  const marketingDepartment = Number(process.env.REACT_APP_MARKETING_DEPARTMENT); 
  const listHeaderStyle = {
    fontSize: open ? 14 : 10,
    marginLeft: open ? 90 : 0,
    marginBottom: -10,
    marginTop: -30
  };
  // feedback submit alert
  const handleFeedbackAlert = (event, reason) => {
    if (reason === "clickaway") return;
    setFeedbackAlert(false);
  };
  // feedback submit alert
  const handleFillFeedbackAlert = (event, reason) => {
    if (reason === "clickaway") return;
    setFillFeedbackAlert(false);
  };

  // show uploaded files list
  const showList = () => {
    var files = document.getElementById("files").files;
    var ul = document.getElementById("uploads");
    ul.innerHTML = "";

    for (let i = 0; i < files.length; i++) {
      const file_extntion = files[i].name.split(".")[1]
      if (files[i].size <= process.env.REACT_APP_MAX_FILE_SIZE_MB * 1024 * 1024 && process.env.REACT_APP_ACCEPT_FEEDBACK_STRING.includes(file_extntion)) {
        var li = document.createElement("li");
        li.appendChild(document.createTextNode(files[i]["name"]));
        ul.appendChild(li);
      }
      else { 
          setFeedbackError(true)
      }
    }
  };

  // send feedback with files to backend
  async function sendFeedback(formData) {
    return fetch(
      `${process.env.REACT_APP_TIMESHEET_DJANGO_URL}/email_feedback/`,
      {
        method: "POST",
        headers: {
        	// "Content-Type": "application/json;charset=utf-8",
        	"Authorization": `Bearer ${localStorage.getItem('token')}`,
        },
        body: formData,
      }
    );
  }

  // gather all files and message info for feedback
  async function handleFeedbackSubmit() {
    var type = document.getElementById("type1").value;
    var feedback = document.getElementById("message").value;
    var files = document.getElementById("files").files;
    console.log("message: ", feedback);
    console.log("type: ", type);
    console.log("files: ", files);

    if (feedback === "") {
      setFillFeedbackAlert(true);
      return;
    }

    const formData = new FormData();
    formData.append("feedback_id", type);
    formData.append("message", feedback);
    for (let i = 0; i < files.length; i++) formData.append("files", files[i]);

    document.getElementById("uploadProgress").style.display = "block";
    var response = await sendFeedback(formData);
    console.log(response);
    if (response.ok) {
      setDialogOpen(false);
      setFeedbackAlert(true);
    } else { 
      alert("Something went wrong !! ")
    }
  }

  // open feedback dialog on feedback button click
  const handleClickOpen = (e) => {
    handleClose(e);
    fetch(`${process.env.REACT_APP_TIMESHEET_DJANGO_URL}/feedback-list/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }).then(async (res) => {
      var data = await res.json();
      console.log(data);
      setDialogOpen(true);
      setFeedbackError(false)
      setTypes(data);
    });
  };

  const handleDownload = () => {};

  // function to get user info
  const getUserView = () => {
    fetch(`${process.env.REACT_APP_TIMESHEET_DJANGO_URL}/user-view/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }).then(async (res) => {
      var data = await res.json();
      console.log(data);
      // if(data.hasOwnProperty("detail") && data["detail"].toLowerCase().includes("expired"))
      // 	window.location = process.env.REACT_APP_TIMESHEET_INDEX_URL
      setUser(data);
    });
  };
  // Get timesheet config data for get HR admin
  const getHRAdminInfo = () => {
    fetch(
      `${process.env.REACT_APP_TIMESHEET_DJANGO_URL}/timesheet/config-value/5/`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    ).then(async (res) => {
      let config_data = await res.json();
      console.log('HR info : ', config_data[0]['value'].split(','))
      sethrAdmin(config_data[0]['value'].split(','))
    })
  }

  const handleToggle = () => {
    setPopopen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (popanchorRef.current && popanchorRef.current.contains(event.target)) {
      return;
    }
    setPopopen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setPopopen(false);
    }
  }

  const prevOpen = React.useRef(popopen);

  React.useEffect(() => {
    getUserView();
    getHRAdminInfo();

    if (prevOpen.current === true && popopen === false) {
      popanchorRef.current.focus();
    }
    prevOpen.current = popopen;
  }, [popopen]);

  // open sidebar drawer
  const handleDrawerOpen = () => {
    console.log(open);
    setOpen(true);
  };

  // close sidebar drawer
  const handleDrawerClose = () => {
    console.log(open);
    setOpen(false);
  };

  return (
    <>
      <AppBar
        position="absolute"
        className={clsx(classes.appBar, open && classes.appBarShift)}
        style={{ backgroundColor: amber[600] }}
      >
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(
              classes.menuButton,
              open && classes.menuButtonHidden
            )}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            component="h1"
            variant="h6"
            noWrap
            className={classes.title}
          >
            Timesheet
          </Typography>
          <IconButton
            color="inherit"
            ref={popanchorRef}
            aria-controls={popopen ? "menu-list-grow" : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
          >
            {user.length !== 0 && (
              <Avatar
                className={classes.small}
                alt={user.first_name}
                src="profile.png"
              />
            )}
          </IconButton>
          <Popper
            open={popopen}
            anchorEl={popanchorRef.current}
            role={undefined}
            transition
            disablePortal
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === "bottom" ? "center top" : "center bottom",
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList
                      autoFocusItem={popopen}
                      id="menu-list-grow"
                      onKeyDown={handleListKeyDown}
                    >
                      <MenuItem
                        onClick={handleClose}
                        component={Link}
                        to="/profile"
                      >
                        Profile
                      </MenuItem>
                      <MenuItem onClick={handleClickOpen}>Feedback</MenuItem>
                      <MenuItem>
                        <a
                          style={{ textDecoration: "none", color: "white" }}
                          href={TMSGuide}
                          download
                        >
                          User Manual
                        </a>
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(
            classes.drawerPaper,
            !open && classes.drawerPaperClose,
            classes.mainsidebar
          ),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <img alt="Timesheet" src={Tms_image} className={classes.Media} />
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>

        <Divider />

        <List style={{ marginTop: "-8px" }}>
          <Tooltip
            title={
              <span
                style={{
                  fontSize: 16,
                }}
              >
                Timesheet
              </span>
            }
            placement="right"
          >
            <ListItem button component={Link} to="/home" className= "list-item">
              <ListItemIcon>
                <AccessTimeIcon />
              </ListItemIcon>
              <ListItemText primary="Timesheet" className="list-item-text"/>
            </ListItem>
          </Tooltip>
          {(user.is_superuser || (user.user_group === 'Talent Management' && hrAdmin.includes(localStorage.getItem('username')))) && (
            <Tooltip
              title={
                <span
                  style={{
                    fontSize: 16,
                  }}
                >
                  User Administration
                </span>
              }
              placement="right"
            >
              <ListItem
                button
                component="a"
                href={`${process.env.REACT_APP_TIMESHEET_DJANGO_URL}/admin/`}
                className="list-item"
              >
                <ListItemIcon className="icon-small">
                  <GroupAddIcon />
                </ListItemIcon>
                <ListItemText primary="User Administration" className="list-item-text"/>
              </ListItem>
            </Tooltip>
          )}
          {(user.is_superuser ||
              user.is_rm) && (
            <Tooltip
              title={
                <span
                  style={{
                    fontSize: 16,
                  }}
                >
                  Reports
                </span>
              }
              placement="right"
            >
              <ListItem button component={Link} to="/report" className="list-item">
                <ListItemIcon className="icon-small">
                  <AssessmentIcon />
                </ListItemIcon>
                <ListItemText primary="Reports" />
              </ListItem>
            </Tooltip>
          )}
          {(user.is_superuser ||
            user.is_hr ||
            user.is_functional_lead ||
            user.is_tech_lead ||
            user.is_stl) && (
            <Tooltip
              title={
                <span
                  style={{
                    fontSize: 16,
                  }}
                >
                  Invoices/Employees
                </span>
              }
              placement="right"
            >
              <ListItem button component={Link} to="/invoices-employees" className="list-item">
                <ListItemIcon className="icon-small">
                  <AssignmentTurnedInIcon />
                </ListItemIcon>
                <ListItemText primary="Invoices/Employees" className="list-item-text" />
              </ListItem>
            </Tooltip>
          )}
          {(user.is_superuser || user.is_hr) && (
            <Tooltip
              title={
                <span
                  style={{
                    fontSize: 16,
                  }}
                >
                  Employee Management
                </span>
              }
              placement="right"
            >
              <ListItem button component={Link} to="/employee-management">
                <ListItemIcon>
                  <SupervisorAccount />
                </ListItemIcon>
                <ListItemText primary="Employee Management" />
              </ListItem>
            </Tooltip>
          )}
        </List>

        <Divider className="custom-divider" />

        <List>
          <ListSubheader style={listHeaderStyle}>DPA ONE</ListSubheader>
          <Tooltip
            title={
              <span
                style={{
                  fontSize: 16,
                }}
              >
                Hono
              </span>
            }
            placement="right"
          >
            <ListItem
              button
              component="a"
              target="_blank"
              href="https://dpa.honohr.com/login"
            >
              <ListItemIcon>
                <VisibilityIcon />
              </ListItemIcon>
              <ListItemText primary="Hono" />
            </ListItem>
          </Tooltip>

          {(user.is_superuser || user.is_functional_lead || user.is_tech_lead || user.is_stl) && (
            <Tooltip
              title={
                <span
                  style={{
                    fontSize: 16,
                  }}
                >
                  Clickup Data Upload
                </span>
              }
              placement="right"
            >
              <ListItem button component={Link} to="/clickup-data" className="list-item">
                <ListItemIcon className="icon-small">
                  <InsertChartIcon />
                </ListItemIcon>
                <ListItemText primary="Clickup Data Upload" className="list-item-text" />
              </ListItem>
            </Tooltip>
          )}
          
          {(user.department_id === marketingDepartment || user.is_superuser) && (
            <Tooltip
              title={
                <span
                  style={{
                    fontSize: 16,
                  }}
                >
                  Marketing Management
                </span>
              }
              placement="right"
            >
              <ListItem button component={Link} to="/marketing-data" className="list-item">
                <ListItemIcon className="icon-small">
                  <InsertChartIcon />
                </ListItemIcon>
                <ListItemText primary="Marketing Management" className="list-item-text" />
              </ListItem>
            </Tooltip>
          )}
          

          <Tooltip
            title={
              <span
                style={{
                  fontSize: 16,
                }}
              >
                Knowledge Management
              </span>
            }
            placement="right"
          >
            <ListItem 
              button
              component="a"
              target="_blank"
              href="https://kms.decimalpointanalytics.com/"
              >
              <ListItemIcon>
                <ScheduleIcon />
              </ListItemIcon>
              <ListItemText primary="Knowledge Management" />
            </ListItem>
          </Tooltip>

          <Tooltip
            title={
              <span
                style={{
                  fontSize: 16,
                }}
              >
                Skill Orbit
              </span>
            }
            placement="right"
          >
            <ListItem
              button
              component="a"
              target="_blank"
              href="https://skillorbit.decimalpointanalytics.com/"
            >
              <ListItemIcon>
                <SchoolIcon />
              </ListItemIcon>
              <ListItemText primary="Skill Orbit" />
            </ListItem>
          </Tooltip>

          <Tooltip
            title={
              <span
                style={{
                  fontSize: 16,
                }}
              >
                Training Platform
              </span>
            }
            placement="right"
          >
            <ListItem button>
              <ListItemIcon>
                <GroupIcon />
              </ListItemIcon>
              <ListItemText primary="Training Platform" />
            </ListItem>
          </Tooltip>
        </List>
        <List style={{ marginTop: "-15px" }}>
          <Tooltip
            title={
              <span
                style={{
                  fontSize: 16,
                }}
              >
                Log-Out
              </span>
            }
            placement="right"
          >
            <ListItem
              className="logout"
              button
              component="a"
              // href={`${process.env.REACT_APP_SSO_LOGOUT_URL}&logoutUri=${process.env.REACT_APP_TIMESHEET_LOGOUT_URL}`}
              href={`${process.env.REACT_APP_SSO_LOGOUT_URL}`}
              onClick={() => {
                localStorage.removeItem("token");
              }}
            >
              <ListItemIcon>
                <ExitToAppIcon />
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </ListItem>
          </Tooltip>
        </List>
      </Drawer>

      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogTitle id="form-dialog-title">Feedback</DialogTitle>
        <DialogContent>
          <DialogContentText>Fill the feedback</DialogContentText>
          <NativeSelect
            inputProps={{
              name: "type",
              id: "type1",
            }}
          >
            {types.map((type) => {
              return <option value={type[1]}>{type[0]}</option>;
            })}
          </NativeSelect>
          <br />
          <br />
          <TextField
            multiline
            rows={4}
            variant="outlined"
            id="message"
            name="message"
            label="Your Feedback"
            fullWidth
            helperText={`Maximum ${process.env.REACT_APP_MAX_FILE_SIZE_MB} MB file size is allowed with ${process.env.REACT_APP_ACCEPT_FEEDBACK_STRING} types.`}
            error={feedbackError}
          />
          <br />
          <br />
          <input
            style={{ display: "none" }}
            accept={process.env.REACT_APP_ACCEPT_FEEDBACK_STRING}
            id="files"
            multiple
            type="file"
            onChange={showList}
          />
          <label htmlFor="files">
            <Button
              variant="contained"
              color="primary"
              component="span"
              startIcon={<CloudUploadIcon />}
            >
              Upload
            </Button>
          </label>
          <ul style={{ listStyleType: "none", padding: 0 }} id="uploads"></ul>
          <div id="uploadProgress" style={{ display: "none" }}>
            <br />
            <LinearProgress />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button type="submit" onClick={handleFeedbackSubmit} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={fillFeedback}
        autoHideDuration={5000}
        onClose={handleFillFeedbackAlert}
      >
        <Alert onClose={handleFillFeedbackAlert} severity="error">
          Give Some Feedback Before Submitting !
        </Alert>
      </Snackbar>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={feedbackAlert}
        autoHideDuration={5000}
        onClose={handleFeedbackAlert}
      >
        <Alert onClose={handleFeedbackAlert} severity="success">
          Feedback submitted
        </Alert>
      </Snackbar>
    </>
  );
}
